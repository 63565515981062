import { BasicLayout } from "./Layout";
import { Home } from "./pages";

function App() {
  return (
    <>
      <BasicLayout>
        <Home />
      </BasicLayout>
    </>
  );
}

export default App;
