import Wrapper from "../Wrapper";

import "./Header.css";

const Header = () => {
  return (
    <div className="header">
      <Wrapper>
        <h2>Subject Access</h2>
      </Wrapper>
    </div>
  );
};

export default Header;
