import Button from "../Button";
import Wrapper from "../Wrapper";

import "./Main.css";

const Main = () => {
  return (
    <Wrapper>
      <main className="main">
        <h3>View your data</h3>
        <div className="grid">
          <div>
            <p>
              <span>
                In accordance with UK and EUI data protection law, General Data
                Protection Regulation (GDPR), you have the right to obtain a
                copy of any personal data we hold about you. This is known as a
                Subject Access Request or SAR for short.
              </span>
            </p>
            <p>
              We will need to verify you are who you say you are before starting
              this process. This will include providing photo ID such as a
              driving licence or passport
            </p>
            <p>
              During the automated identity verification process, you will need
              a <span>current UK driving licence</span> (plastic version){" "}
              <span>OR</span> one document from list <span>A</span> AND one
              document from list <span>B</span> (see table on the right).{" "}
            </p>
            <p>
              Each document in List B should be in your own name, sent to your
              home address and dated within the past 3 months.
            </p>
            <p>
              Please make sure you have the required documents you proceed with
              the request
            </p>
            <p>
              You will also need a smart phone, with a camera, to complete the
              automated identification. If you do NOT have a smart phone, please
              contact us directly at <span>privacy@percayso-inform.com</span>
            </p>
          </div>
          <div>
            {/* Table section */}
            <div className="table-wrapper">
              <div className="table">
                <p className="table-header">List A</p>
                <ul className="table-list">
                  <li className="table-list-item">Passport</li>
                  <li className="table-list-item">
                    Any other form of photographic government issued licence
                  </li>
                </ul>
              </div>
              <div className="table">
                <p className="table-header">List B</p>
                <ul className="table-list">
                  <li className="table-list-item">
                    Utility bill connected to your address (gas, electricity,
                    water, landline, telephone)
                  </li>
                  <li className="table-list-item">Bank statement</li>
                  <li className="table-list-item">
                    Council tax bill/Confirmation of electoral registration
                  </li>
                </ul>
              </div>
            </div>
            {/* Texts */}
            <div>
              <p>
                Please click the button below to request a copy of your data.
                Your request will take approximately 30 days to complete.
              </p>
              <p>
                If you encounter any problems or have any questions before or
                during the SAR process, do not hesitate to contact us at{" "}
                <span>privacy@percayso-inform.com</span>
              </p>

              <div className="button-wrapper">
                <Button title="Start your request" />
              </div>
            </div>
          </div>
        </div>
        {/* Right section */}
      </main>
    </Wrapper>
  );
};

export default Main;
