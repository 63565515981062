import "./Button.css";

interface ButtonProps {
  title: string;
  onClick?: () => void;
}

const Button = (props: ButtonProps) => {
  return (
    <button onClick={props.onClick} className="button">
      {props.title}
    </button>
  );
};

export default Button;
