import React from "react";
import Wrapper from "../Wrapper";
import {AiOutlineFacebook, AiOutlineLinkedin, AiOutlineTwitter} from 'react-icons/ai'
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <Wrapper>
        <div className="footer-content">
        <div className="links">
            <a href="/privacy-policy" target="_blank">
              Privacy Policy
            </a>
            <a href="/privacy-policy" target="_blank">
              Terms and Conditions
            </a>
            <a href="/privacy-policy" target="_blank">
              Modern Slavery Statement
            </a>
            <a href="/privacy-policy" target="_blank">
              Cookie Policy
            </a>
            <a href="/privacy-policy" target="_blank">
              FAQs
            </a>
          </div>
          <p className="link-title">Follow us on</p>
          <div className="links">
          <a href="/privacy-policy" target="_blank">
             <AiOutlineLinkedin/>
            </a>
          <a href="/privacy-policy" target="_blank">
             <AiOutlineFacebook/>
            </a>
          <a href="/privacy-policy" target="_blank">
             <AiOutlineTwitter/>
            </a>
          </div>
        </div>
      </Wrapper>
    </footer>
  );
};

export default Footer;
