import React from "react";
import "./Wrapper.css";

interface WrapperProps {
  children: React.ReactNode;
}

const Wrapper = (props: WrapperProps) => {
  return <div className="wrapper">{props.children}</div>;
};

export default Wrapper;
